import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from 'vuex-router-sync'
import '@/assets/tailwind.css'

export function createApp() {
  Vue.config.productionTip = false
  Vue.use(VueMeta)

  const router = createRouter()
  const store = createStore()

  // sync so that route state is available as part of the store
  sync(store, router)

  // create the app instance, injecting both the router and the store
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  // expose the app, the router and the store.
  return { app, router, store }
}
