import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { fetchCompany } from '../../api'
import { fetchArticle, fetchAllArticles } from '../../api/cbd-research-library'

export function createStore() {
  return new Vuex.Store({
    state: () => ({
      companies: {},
      articles: []
    }),
    actions: {
      fetchCompany({ commit }, slug) {
        return fetchCompany(decodeURIComponent(slug)).then(company => {
          if (!company) throw new Error('Company not found')
          company.slug = slug
          commit('addCompany', company)
        })
      },
      fetchArticle({ commit }, slug) {
        return fetchArticle(slug).then(article => {
          if (article) {
            commit('addArticle', article)
          }
        })
      },
      fetchAllArticles({ commit }) {
        return fetchAllArticles().then(articles => {
          if (articles) {
            commit('addAllArticles', articles)
          }
        })
      }
    },
    mutations: {
      addCompany(state, company) {
        Vue.set(state.companies, company.slug, company)
      },
      addArticle(state, article) {
        state.articles = [...state.articles, article]
        Vue.set(state.companies, article.path, article)
      },
      addAllArticles(state, articles) {
        state.articles = articles
      }
    },
    modules: {}
  })
}
