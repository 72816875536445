<template>
  <nav class="bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/">
              <img
                class="block -mb-2 h-5 w-auto"
                src="@/assets/images/CBDCOAS-wide.svg"
                alt="CBDCOAs logo"
              />
            </router-link>
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <router-link
            to="/"
            exact
            class="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out"
          >
            Home
          </router-link>
          <router-link
            to="/what-is-cbdcoas"
            class="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-blue-300 transition duration-150 ease-in-out"
          >
            What is CBDCOAs?
          </router-link>
          <router-link
            to="/cbd-research-library"
            class="ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-blue-300 transition duration-150 ease-in-out"
          >
            CBD Research Library
          </router-link>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <button
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            aria-label="Main menu"
            aria-expanded="false"
            @click="
              () => {
                hamburger = !hamburger;
              }
            "
          >
            <!-- Icon when menu is closed. -->
            <svg
              :class="{ block: !hamburger, hidden: hamburger }"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!-- Icon when menu is open. -->
            <svg
              :class="{ block: hamburger, hidden: !hamburger }"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!--
      Menu open: "block", Menu closed: "hidden"
    -->
    <div
      :class="{ block: hamburger, hidden: !hamburger }"
      class="sm:hidden"
      @click="hamburger = false"
    >
      <div class="pt-2 pb-3">
        <router-link
          to="/"
          exact
          class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 transition duration-150 ease-in-out"
        >
          Home
        </router-link>
        <router-link
          to="/what-is-cbdcoas"
          class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
        >
          What is CBDCOAs?
        </router-link>
        <router-link
          to="/cbd-research-library"
          class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
        >
          CBD Research Library
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      hamburger: false
    };
  }
};
</script>

<style scoped>
.router-link-active {
  @apply border-blue-500 text-gray-900 bg-blue-50;
}
@screen sm {
  .router-link-active {
    @apply bg-transparent;
  }
}
</style>
