<template>
  <div id="app">
    <Navbar />
    <router-view
      class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 text-gray-900"
    />
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "CBDCOAs",
    // all titles will be injected into this template
    titleTemplate: "%s - CBDCOAs",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Use CBDCOAs to find and view Certificates of Analysis (CoA's) for CBD companies. Each CoA is watermarked with our patented technology."
      }
    ],
    link: [{ rel: "stylesheet", href: "/inter/inter.css" }]
  },
  components: {
    Navbar,
    Footer
  }
};
</script>
