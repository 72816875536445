import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Vue from 'vue'

import { fetchAllCompanyPaths } from '../../api'
import { fetchAllArticlePaths } from '../../api/cbd-research-library'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    changefreq: 'monthly',
    priority: '1.0'
  },
  {
    path: '/what-is-cbdcoas',
    name: 'about',
    component: () => import('../views/About.vue'),
    changefreq: 'monthly',
    priority: '0.9'
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/Error.vue')
  },
  {
    path: '/cbd-research-library',
    name: 'research-library',
    component: () => import('../views/cbd-research-library/index.vue'),
    changefreq: 'yearly',
    priority: '0.9'
  },
  {
    path: '/cbd-research-library/:article',
    name: 'article',
    component: () => import('../views/cbd-research-library/_article.vue'),
    changefreq: 'yearly',
    priority: '0.9',
    provider: fetchAllArticlePaths
  },
  {
    path: '/:slug',
    name: 'company',
    component: () => import('../views/_slug/index.vue'),
    changefreq: 'monthly',
    priority: '0.8',
    provider: fetchAllCompanyPaths
  },
  {
    path: '/:slug/:product',
    name: 'product',
    component: () => import('../views/_slug/_product.vue'),
    changefreq: 'monthly',
    priority: '0.7',
    provider: fetchAllCompanyPaths
  },
  { path: '*', redirect: '/error' }
]

export const createRouter = () => {
  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  })
}
