const realm = 
  'https://webhooks.mongodb-realm.com/api/client/v2.0/app/cbdcoas-caxto/service/cbdcoas/incoming_webhook/get'

export function fetchCompany(slug) {
  return fetch(`${realm}?url=${slug}`)
    .then(response => response.json())
    .then(({ data }) => data)
    .catch(e => console.log(e))
}

export async function fetchAllCompanyPaths(docs = []) {
  const res = await fetch(realm)
    .then(response => response.json())
    .then(({ data }) => data)
    .catch(e => console.log(e))

  res.forEach(({ url, products }) => {
    docs.push('/' + url)

    if (products && Object.keys(products).length > 0) {
      Object.keys(products).forEach(key => {
        docs.push(`/${url}/${encodeURIComponent(key)}`)
      });
    }
  });
  
  return docs
}
