import articles from './articles.json'

export function fetchArticle(path) {
  return new Promise((resolve, reject) => {
    const article = articles.find(article => article.path === path)
    if (article) {
      resolve(article)
    } else {
      reject('Article not found')
    }
  })
}

export function fetchAllArticles() {
  return new Promise(resolve => {
    resolve(articles)
  })
}

export function fetchAllArticlePaths() {
  return articles.map(article => '/cbd-research-library/' + article.path)
}
