<template>
  <main class="container mx-auto px-4">
    <section class="py-12 px-4">
      <div class="w-full max-w-2xl mx-auto text-center">
        <img
          class="block mt-6 mb-12 mx-auto h-24 w-auto"
          src="@/assets/images/CBDCOAS-wide.svg"
          alt="CBDCOAs logo"
        />
        <form class="w-full max-w-xl mx-auto" @submit="form">
          <div class="flex flex-wrap mb-4">
            <div class="w-full md:w-2/3">
              <input
                v-model="slug"
                class="appearance-none block w-full py-3 px-4 mb-4 md:mb-0 leading-snug text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded md:rounded-r-none focus:outline-none"
                type="text"
                required
                placeholder="cbdcoas.com"
              />
            </div>
            <div class="w-full md:w-1/3">
              <button
                type="submit"
                class="inline-block w-full py-4 px-8 leading-none text-white bg-blue-500 hover:bg-blue-600 rounded md:rounded-l-none"
              >
                Search
              </button>
            </div>
          </div>
          <h1 class="text-sm text-gray-500 leading-relaxed">
            Search through our collection of certificates of analysis from our
            clients.
          </h1>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  metaInfo: {
    title: "Home"
  },
  data() {
    return {
      slug: ""
    };
  },
  methods: {
    form(e) {
      e.preventDefault();
      if (this.slug.length) this.$router.push(`/${this.slug}`);
    }
  }
};
</script>
